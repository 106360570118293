import React, { useEffect, useState, useRef } from "react";
import Header from "../layoutcomponents/Header";
import Sidebar from "../layoutcomponents/Sidebar";
import Footer from "../layoutcomponents/Footer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import myImage from "../images/no-img.jpg";
import Upload from "../components/Upload";
import { useLocation, useNavigate } from "react-router-dom";
import { GetDistIndi, GetRegionalOffice } from "../functions/fx_initialdata";
import {
  BsEye,
  BsCheckCircleFill,
  BsCircle,
  BsPencilSquare,
  BsEyeFill,
} from "react-icons/bs";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import PaginationInv from "../components/PaginationInv";
import InventoryProfile from "../components/modal/InventoryProfile";
import {
  AddInventory,
  GetInventory,
  GetInventoryNonEstate,
  GetAllInventory,
  GetProjectInfobyCode,
  GetProjectInfobyBlk,
  UpdateInventory,
  GetImage,
  GetImageFlood,
  GetImageSeis,
  GetProjectInfobyId,
  GetGeomEstate,
  GetGeomNonEstate,
  GetAllInventoryUnsold,
  GetInventoryUnsoldbyRegion,
  GetUnsoldNonestateProject,
} from "../functions/fx_inventory";

// MAP //
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Polygon,
  Circle,
} from "react-leaflet";
import { WMSTileLayer } from "react-leaflet";

const Form2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { triggerFetch, selectedData } = location.state || {};
  const [districtValueInv, setDistrictValueInv] = useState("");
  const [regionValueInv, setRegionValueInv] = useState("");
  const [regioninv, setRegionInv] = useState([]);
  const [districtinv, setDistrictInv] = useState([]);
  const [readonly, setReadOnly] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const [accesslevel, setAccessLevel] = useState("");
  const [appmarketval, setAppMarketval] = useState("");
  const [apprentval, setAppRentval] = useState("");
  const [appvaldate, setAppValdate] = useState("");
  const [mapcapturedimage, setMapCapturedImage] = useState(null);

  const [imagedata, setImageDta] = useState(null);
  const [openInventoryModal, setopenInventoryModal] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const handleclose = () => setopenModal(false);

  const [openUplod, setOpenUplod] = useState(false);
  const toggleupload = () => setOpenUplod(!openUplod);

  const [propertyid, setPropertyID] = useState("");
  const [zoneordinace, setZoneOrdinance] = useState("");
  const [catvalue, setCategory] = useState("");
  const [classvalue, setClass] = useState("");
  const [lot, setLot] = useState("");
  const [blk, setBlk] = useState("");
  const [phase, setPhase] = useState("");
  const [pack, setPackage] = useState("");
  const [lotarea, setLotArea] = useState("");

  const [area, setArea] = useState("");
  const [subblk, setSubblk] = useState("");
  const [supblk, setSupblk] = useState("");
  const [stallno, setStallNo] = useState("");
  const [flrarea, setFlrArea] = useState("");
  const [subplan, setSubPlan] = useState("");
  const [subplanno, setSubPlanNo] = useState("");

  const [withanodata, setWithAnoData] = useState("");
  const [subplanfiledate, setSubplanfiledate] = useState("");

  const [tctno, setTCTNo] = useState("");
  const [titlestat, setTitleStat] = useState("");
  const [statowner, setStatOwner] = useState("");
  const [otherowner, setOtherOwner] = useState("");

  const [awardstat, setAwardStatus] = useState("Unsold/Undisposed");
  const [physicalstat, setPhysicalStatus] = useState("");
  const [physicalstatothers, setPhysicalStatOthers] = useState("");
  const [frontage, setFrontage] = useState("");
  const [shape, setShape] = useState("");
  const [topography, setTopography] = useState("");
  const [foodassessment, setFoodAssessment] = useState("");
  const [sysmicanalysis, setSysmicAnalysis] = useState("");

  const [electricprov, setElectricProvider] = useState("");
  const [waterprov, setWaterProvider] = useState("");
  const [sesu, setWaterSesu] = useState("");
  const [acctstatus, setAccctstatus] = useState("");
  const [legalstat, setLegalStat] = useState("");
  const [actiontaken, setActionTaken] = useState("");
  const [executiveissuance, setExecutiveIssuances] = useState("");
  const [appstat, setAppStat] = useState("");
  const [appvalid, setAppValid] = useState("");
  const [pricingstat, setPricingStat] = useState("");
  const [pricevalid, setPriceValid] = useState("");
  const [modedispo, setModeDispo] = useState("");
  const [pbtype, setPBType] = useState("");
  const [othersmodedipo, setOtherModeDipo] = useState("");

  const [propmodedispo, setPropModeDispo] = useState("");
  const [proppbtype, setPropPBType] = useState("");
  const [propothersmodedipo, setPropOtherModeDipo] = useState("");

  const [structuredesc, setStructureDesc] = useState("");
  const [inventoryid, setInventoryId] = useState("");

  const [geomid, setGeomId] = useState("");
  const [comcode, setComCode] = useState("");
  const [awardeename, setAwardeeName] = useState("");

  const [estateprojects, setEstateProjects] = useState([]);
  const [nonestateprojects, setNonEstateProjects] = useState([]);
  const [occupiedstat, setOccupiedStat] = useState([]);
  const [allinventoryprojects, setAllInventoryProject] = useState([]);

  const [buttonstat, setButton] = useState("Submit");

  const [setaddress, setAddress] = useState([]);
  const [addressvalue, setAddressValue] = useState("");

  const [parcelcoordinates, setParcelCoordinates] = useState([]);
  const [setcenter, setCenteroid] = useState([]);
  const multiPolygon = parcelcoordinates;

  const borderOptions = {
    color: "red", // Border color
    weight: 2, // Border thickness
    fillColor: "yellow", // No fill color
  };
  const redOptions = { color: "red", dashArray: "10, 5", fill: false };

  const [fetchimage, setFetchedImage] = useState(myImage);
  const [floodimageprev, FloodImagePrev] = useState("");
  const [seisimageprev, SeisImagePrev] = useState("");
  const [fetchimagepass, setFetchedImagePass] = useState("");
  const [fetchimageflood, setFetchImageFlood] = useState("");
  const [fetchimageseis, setFetchImageSeis] = useState("");
  const [useuuid, setUUID] = useState("");

  const [inventoryprofile, setInventoryProfile] = useState([]);
  const [awardstatusagreement, setAwardStatusagreement] = useState("");
  const [awardstatusagreementwith, setAwardStatusagreementwith] = useState("");

  const [pbawardee, setPbawardee] = useState("");
  const [pbdateaward, setPbdateaward] = useState("");
  const [pbsellprice, setPbsellprice] = useState("");
  const [pbcontype, setPbcontype] = useState("");
  const [pblessee, setPblessee] = useState("");
  const [pbactoccu, setPbactoccu] = useState("");
  const [pbleaserate, setPbleaserate] = useState("");
  const [pbleaseterm, setPbleaseterm] = useState("");

  const [passgeom, setPassGeom] = useState("");
  const [passcenteroid, setPassCenteroid] = useState("");

  const [pbdateawardlease, setPbdateawardlease] = useState("");
  const [usufructentity, setUsufructEntity] = useState("");
  const [agreementterm, setAgreementTerm] = useState("");

  const [fileNameFlood, setFileNameFlood] = useState("");
  const [fileNameSeis, setFileNameSeis] = useState("");
  const [fileNameSiteMap, setFileNameSiteMap] = useState("");

  const [imagedataflood, setImageDataFlood] = useState(null);
  const [imagedataseis, setImageDataSeis] = useState(null);

  const [firstbiddate, setFirstBidDate] = useState("");
  const [secbiddate, setSecBidDate] = useState("");
  const [foreclosedprop, setForeClosedProp] = useState("");

  const [pbsaleawardee, setPbSaleAwardee] = useState("");
  const [pbsaledateaward, setPbSaleDateAward] = useState("");
  const [pbsaleselprice, setPbSaleSelPrice] = useState("");
  const [pbsalecontype, setPbSaleConType] = useState("");

  const [pbleaselessee, setPbLeaseLessee] = useState("");
  const [pbleasedateaward, setPbLeaseDateAward] = useState("");
  const [pbleaseactoccu, setPbLeaseActOccu] = useState("");
  const [pbleaseratepermon, setPbLeaseRatePerMon] = useState("");
  const [pbleaseleaseterm, setPbLeaseLeaseTerm] = useState("");

  const [road, setRoadName] = useState([""]);

  const addInputField = () => {
    setRoadName([...road, ""]);
  };

  const handleInputChange = (index, event) => {
    const values = [...road];
    values[index] = event.target.value;
    setRoadName(values);
  };

  const removeInputField = (index) => {
    const values = [...road];
    values.splice(index, 1);
    setRoadName(values);
  };

  const mapRef = useRef();
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyWarning = (message) =>
    toast.warn(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleChangeCat = (event) => {
    setCategory(event.target.value);

    setClass("");
  };

  const handleChangeClass = (event) => {
    setClass(event.target.value);
    if (
      event.target.value === "1" ||
      event.target.value === "2" ||
      event.target.value === "3" ||
      event.target.value === "4"
    ) {
      setCategory("Non-estate");
    } else {
      setCategory("Estate");
    }
  };

  const handleSetPropertyIDNonEstate = (selected) => {
    if (selected !== null) {
      getProjectinfobyId(selected.value);

      setPropertyID(selected);
    } else {
      setPropertyID("");
      setAddress([]);
      setGeomId("");
      setParcelCoordinates([]);
      setTCTNo("");
      setLotArea("");
      setCenteroid([]);
    }
  };

  const handleSetPropertyIDEstate = (selected) => {
    // alert(selected.value)
    if (selected !== null) {
      getProjectinfobyCode(selected.value);
      setPropertyID(selected);
    } else {
      setPropertyID("");
      setComCode("");
      setGeomId("");
      setBlk("");
      setLot("");
      setLotArea("");
      setPhase("");
      setPackage("");
      setTCTNo("");
      setParcelCoordinates([]);
      setCenteroid([]);
      setAddress([]);
      setAddressValue("");
    }
  };

  const getProjectinfobyId = (proj_code) => {
    GetProjectInfobyId(proj_code)
      .then((value) => {
        // setComCode(value.data[0].)
        setAddress(value.data);
        setGeomId(value.data[0].id);

        console.log(value.data[0].geojson);
        let obj = JSON.parse(value.data[0].geojson);

        console.log(obj.coordinates);
        setParcelCoordinates(obj.coordinates);
        setTCTNo(value.data[0].title_num);
        setLotArea(value.data[0].area_sqm);

        let sumLat = 0;
        let sumLng = 0;
        let numPoints = 0;
        obj.coordinates.forEach((polygon) => {
          polygon.forEach((ring) => {
            ring.forEach((point) => {
              sumLat += point[1];
              sumLng += point[0];
              numPoints++;
            });
          });
        });

        const centerLat = sumLat / numPoints;
        const centerLng = sumLng / numPoints;
        setCenteroid([centerLng, centerLat]);

        if (mapRef.current) {
          const map = mapRef.current;
          map.flyTo(obj.coordinates[0][0][1], 18, {
            duration: 2,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getProjectinfobyCode = (proj_code) => {
    // setSetloading(true);
    GetProjectInfobyCode(proj_code)
      .then((value) => {
        setAddress(value.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleSetPropertyBlock = (selected) => {
    if (selected === null || selected === undefined) {
      // setPropertyID("");
      setComCode("");
      setGeomId("");
      setBlk("");
      setLot("");
      setLotArea("");
      setPhase("");
      setPackage("");
      setTCTNo("");
      setParcelCoordinates([]);
      setCenteroid([]);
      setAddressValue("");
      return; // Exit the function early if `selected` is null or undefined
    }

    // if (selected.value === "?" || selected.value === null) {
    //   notifyWarning("No Address. Please select one with address");
    //   return; // Exit the function early if `selected.value` is "?" or null
    // }

    // Proceed with the rest of the function if `selected` is valid
    GetProjectBlkLot(selected.value);
    setAddressValue(selected);
  };

  const GetProjectBlkLot = (proj_code) => {
    // setSetloading(true);
    GetProjectInfobyBlk(proj_code)
      .then((value) => {
        // console.log(value.data[0].commcod);

        setComCode(value.data[0].commcod);
        setGeomId(value.data[0].id);
        setBlk(value.data[0].blk === null ? "" : value.data[0].blk);
        setLot(value.data[0].lot_num === null ? "" : value.data[0].lot_num);
        setLotArea(
          value.data[0].area_sqm === null ? "" : value.data[0].area_sqm
        );
        setPhase(value.data[0].phase === null ? "" : value.data[0].phase);
        setPackage(value.data[0].package === null ? "" : value.data[0].package);
        setTCTNo(value.data[0].tct_no === null ? "" : value.data[0].tct_no);
        // value.data[0].disp_stat === "3"
        //   ? setAwardStatus("Sold/Disposed")
        //   : value.data[0].disp_stat === "1"
        //   ? setAwardStatus("Unsold/Undisposed")
        //   : setAwardStatus("");

        let obj = JSON.parse(value.data[0].geojson);
        setParcelCoordinates(obj.coordinates);
        let sumLat = 0;
        let sumLng = 0;
        let numPoints = 0;
        obj.coordinates.forEach((polygon) => {
          polygon.forEach((ring) => {
            ring.forEach((point) => {
              sumLat += point[1];
              sumLng += point[0];
              numPoints++;
            });
          });
        });

        const centerLat = sumLat / numPoints;
        const centerLng = sumLng / numPoints;
        setCenteroid([centerLng, centerLat]);

        if (mapRef.current) {
          const map = mapRef.current;

          map.flyTo(obj.coordinates[0][0][1], 18, {
            duration: 2,
          });
        }
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getAllProjectsNonEstate = () => {
    // setSetloading(true);
    GetInventoryNonEstate()
      .then((value) => {
        setNonEstateProjects(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getAllProjectsEstate = () => {
    // setSetloading(true);
    GetInventory()
      .then((value) => {
        setEstateProjects(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getAllInventoryProjects = () => {
    // setSetloading(true);
    GetAllInventoryUnsold()
      .then((value) => {
        setAllInventoryProject(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    setDistrictValueInv("");

    setRegionValueInv("");
  };
  const handleChangeSubPlan = (event) => {
    if (
      event.target.value === "Not yet Subdivided" ||
      event.target.value === "Subdivision Plan No." ||
      event.target.value === "For Subdivision"
    ) {
      setSubPlanNo("");
      setSubplanfiledate("");
    }
    setSubPlan(event.target.value);
  };

  const handleSubdivisionPlan = (event) => {
    if (event.target.value.length >= 1) {
      setSubPlan("Subdivision Plan No.");
      setSubplanfiledate("");
    } else {
      setSubPlan("");
    }
    setSubPlanNo(event.target.value);
  };

  const handleWithAnoData = (event) => {
    if (event.target.value.length >= 1) {
      setTitleStat("With Annotation");
    } else {
      setTitleStat("");
    }
    setWithAnoData(event.target.value);
  };

  const handleSubdivisionPlanfiledate = (event) => {
    if (event.target.value.length >= 1) {
      setSubPlan("For Subdivision");
      setSubPlanNo("");
    } else {
      setSubPlan("");
    }
    setSubplanfiledate(event.target.value);
  };

  const handleChangeTitleStat = (event) => {
    setTitleStat(event.target.value);
  };

  const handleChangeStatOwner = (event) => {
    setStatOwner(event.target.value);
    if (event.target.value === "1" || event.target.value === "2") {
      setOtherOwner("");
    }
  };

  const handleOccupiedOthers = (event) => {
    setOtherOwner(event.target.value);
    if (event.target.value.length >= 1) {
      setStatOwner("3");
    } else {
      setStatOwner("");
    }
  };

  const handleChangeAwardStatus = (event) => {
    setAwardStatus(event.target.value);
  };

  const handleAwardStatSelectAgreement = (selected) => {
    if (selected !== null && selected !== undefined) {
      setAwardStatus("With Agreement");
    } else {
      setAwardStatus("");
      setAwardStatusagreementwith("");
    }
    setAwardStatusagreement(selected);
  };

  const handleChangePhysicalStatus = (event) => {
    if (event.target.value === "Occupied" || event.target.value === "Vacant") {
      setPhysicalStatOthers("");
    }
    if (event.target.value === "Others" || event.target.value === "Vacant") {
      setOccupiedStat([]);
    }
    setPhysicalStatus(event.target.value);
  };

  const handleChangeOccupied = (event) => {
    setPhysicalStatus("Occupied");
    setPhysicalStatOthers("");
    const { value, checked } = event.target;
    if (checked) {
      setOccupiedStat([...occupiedstat, value]); // add the value to the array
    } else {
      setOccupiedStat(occupiedstat.filter((item) => item !== value)); // remove the value from the array
    }
    // if (occupiedstat.length >= 1) {
    //   setPhysicalStatus("Occupied");
    // } else {
    //   setPhysicalStatus("");
    // }
  };

  const handlePhysicalOthers = (event) => {
    if (event.target.value.length >= 1) {
      setPhysicalStatus("Others");
      setOccupiedStat([]);
    } else {
      setPhysicalStatus("");
    }
    setPhysicalStatOthers(event.target.value);
  };
  const handleChangeSesu = (event) => {
    setWaterSesu(event.target.value);
  };

  const handleChangeLegalStat = (event) => {
    setLegalStat(event.target.value);
  };

  const handleChangeAppStat = (event) => {
    // setAppMarketval("");
    // setAppRentval("");
    // setAppValdate("");
    setAppStat(event.target.value);
    if (event.target.value === "2") {
      setAppMarketval("");
      setAppRentval("");
      setAppValid("");
    }
  };

  const handleChangeAppStatValid = (event) => {
    setAppValid(event.target.value);
    setAppMarketval("");
    setAppRentval("");
    setAppValdate("");

    if (event.target.value.length >= 1) {
      setAppStat("1");
    }
  };

  const handleChangePricingStat = (event) => {
    if (event.target.value === "2") {
      setPriceValid("");
    }
    setPricingStat(event.target.value);
  };

  const handleChangePricingStatValid = (event) => {
    if (event.target.value.length >= 1) {
      setPricingStat("1");
    } else {
      setPricingStat("");
    }

    setPriceValid(event.target.value);
  };

  const handleChangeModeDispo = (event) => {
    setModeDispo(event.target.value);
    if (event.target.value === "2" || event.target.value === "3") {
      setPBType("");
    }
    if (event.target.value === "1" || event.target.value === "2") {
      setOtherModeDipo("");
    }
  };

  const handleChangePBType = (event) => {
    setModeDispo("1");
    setPBType(event.target.value);
    setOtherModeDipo("");
  };

  const handleChangeModeDispoOthers = (event) => {
    if (event.target.value.length >= 1) {
      setModeDispo("3");
      setPBType("");
    } else {
      setModeDispo("");
    }
    setOtherModeDipo(event.target.value);
  };

  const handleProposedModeDispo = (event) => {
    setPropModeDispo(event.target.value);
    if (event.target.value === "2" || event.target.value === "3") {
      setPropPBType("");
    }
    if (event.target.value === "1" || event.target.value === "2") {
      setPropOtherModeDipo("");
    }
  };

  const handleProposedPBType = (event) => {
    setPropModeDispo("1");
    setPropPBType(event.target.value);
    setPropOtherModeDipo("");
  };

  const handleProposedModeDispoOthers = (event) => {
    if (event.target.value.length >= 1) {
      setPropModeDispo("3");
      setPropPBType("");
    } else {
      setPropModeDispo("");
    }
    setPropOtherModeDipo(event.target.value);
  };

  const handleChangeacctstatus = (event) => {
    setAccctstatus(event.target.value);
  };

  const handleAddInventory = () => {
    const resetStateVariables = () => {
      setPropertyID("");
      setZoneOrdinance("");
      setCategory("");
      setClass("");
      setLot("");
      setBlk("");
      setPhase("");
      setPackage("");
      setLotArea("");
      setFlrArea("");
      setStallNo("");
      setSubPlan("");
      setSubPlanNo("");
      setTCTNo("");
      setTitleStat("");
      setStatOwner("");
      setOtherOwner("");
      setPhysicalStatus("");
      setPhysicalStatOthers("");
      setFrontage("");
      setShape("");
      setTopography("");
      setFoodAssessment("");
      setSysmicAnalysis("");
      setRoadName([""]);
      setElectricProvider("");
      setWaterProvider("");
      setWaterSesu("");
      setLegalStat("");
      setActionTaken("");
      setExecutiveIssuances("");
      setAppStat("");
      setAppValid("");
      setPricingStat("");
      setPriceValid("");
      setModeDispo("");
      setPBType("");
      setOtherModeDipo("");
      setStructureDesc("");
      setInventoryId("");
      setAddressValue("");
      setAccctstatus("");
      setAwardStatusagreement("");
      setFetchedImage(myImage);
      setSubplanfiledate("");
      setOccupiedStat([]);
      setSubblk("");
      setSupblk("");
      setArea("");
      setPropModeDispo("");
      setPropPBType("");
      setPropOtherModeDipo("");
      setAppMarketval("");
      setAppRentval("");
      setAppValdate("");
      setRegionValue("");
      setDistrictValue("");
      setImageDta("");
      setImageDataFlood(null);
      setImageDataSeis(null);
      setFileNameFlood("");
      setFileNameSeis("");
      setForeClosedProp("");
      setFileNameSiteMap("");

      setDistrictValueInv("");

      setRegionValueInv("");
      setWithAnoData("");
    };

    const handleSuccess = () => {
      notifySuccess("Successfully Added!");

      resetStateVariables();
      // Delay navigation to give time for the notification to be seen
      setTimeout(() => {
        navigate("/inventory");
      }, 2000); // 1000ms delay (1 second)
    };

    // Function to handle error notification
    const handleError = (message) => {
      notifyWarning(message);
    };

    if (classvalue === "") {
      notifyWarning(
        "Please fill in Land Use Classification (Commercial,Industrial, etc.)"
      );
    } else if (accesslevel === "3" && addressvalue === "") {
      handleError("Please select an address");
      return;
    } else if (propertyid === "") {
      handleError("Please fill in Property Identification");
    } else if (accesslevel === "1" && regionValue === "") {
      handleError("Please choose Region and District Office");
    } else if (accesslevel === "1" && districtValue === "") {
      handleError("Please choose a District Office");
    } else if (!imagedata) {
      handleError("Please site map image");
    } else if (!imagedataflood) {
      handleError("Please upload flood assessment image");
    } else if (!imagedataseis) {
      handleError("Please upload seismic analysis image");
    } else {
      let res = AddInventory(
        propertyid.label,
        zoneordinace,
        catvalue,
        classvalue,
        lot,
        blk,
        phase,
        pack,
        lotarea,
        flrarea,
        subplan,
        subplanno,
        tctno,
        titlestat,
        statowner,
        otherowner,
        awardstat,
        physicalstat,
        physicalstatothers,
        structuredesc,
        frontage,
        shape,
        topography,
        foodassessment,
        sysmicanalysis,
        road,
        electricprov,
        waterprov,
        sesu,
        legalstat,
        actiontaken,
        executiveissuance,
        appstat,
        appvalid,
        pricingstat,
        pricevalid,
        modedispo,
        pbtype,
        othersmodedipo,
        propertyid.value,
        occupiedstat,
        imagedata,
        geomid,
        comcode,
        acctstatus,
        awardstatusagreement.value,
        subplanfiledate,
        awardstatusagreementwith,
        area,
        subblk,
        supblk,
        pbawardee,
        pbdateaward,
        pbsellprice,
        pbcontype,
        pblessee,
        pbactoccu,
        pbleaserate,
        pbleaseterm,
        propmodedispo,
        proppbtype,
        propothersmodedipo,
        pbdateawardlease,
        usufructentity,
        agreementterm,
        stallno,
        appmarketval,
        apprentval,
        appvaldate,
        regionValue,
        districtValue,
        imagedataflood,
        imagedataseis,
        firstbiddate,
        secbiddate,
        foreclosedprop,
        pbsaleawardee,
        pbsaledateaward,
        pbsaleselprice,
        pbsalecontype,
        pbleaselessee,
        pbleasedateaward,
        pbleaseactoccu,
        pbleaseratepermon,
        pbleaseleaseterm,
        withanodata
      );
      res
        .then((value) => {
          if (value === "0000") {
            handleSuccess();
          } else {
            handleError("Error: Entries did not insert into database...");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUpdateInventory = () => {
    // Function to handle success notification and data fetching
    const handleSuccess = () => {
      resetStateVariables();
      notifySuccess("Updated Successfully");

      // Delay navigation to give time for the notification to be seen
      setTimeout(() => {
        navigate("/inventory");
      }, 2000); // 1000ms delay (1 second)
    };

    const resetStateVariables = () => {
      setReadOnly(false);

      getAllInventoryProjects();
      setPropertyID("");
      setZoneOrdinance("");
      setCategory("");
      setClass("");
      setLot("");
      setBlk("");
      setPhase("");
      setPackage("");
      setLotArea("");
      setFlrArea("");
      setStallNo("");
      setSubPlan("");
      setSubPlanNo("");
      setTCTNo("");
      setTitleStat("");
      setStatOwner("");
      setOtherOwner("");

      setPhysicalStatus("");
      setPhysicalStatOthers("");
      setFrontage("");
      setShape("");
      setTopography("");
      setFoodAssessment("");
      setSysmicAnalysis("");
      setRoadName([""]);
      setElectricProvider("");
      setWaterProvider("");
      setWaterSesu("");
      setLegalStat("");
      setActionTaken("");
      setExecutiveIssuances("");
      setAppStat("");
      setAppValid("");
      setPricingStat("");
      setPriceValid("");
      setModeDispo("");
      setPBType("");
      setOtherModeDipo("");
      setStructureDesc("");
      setInventoryId("");
      setAddressValue("");
      setOccupiedStat("");
      setAccctstatus("");
      setFetchedImage(myImage);
      setAwardStatusagreement("");
      setAwardStatusagreementwith("");
      setSubplanfiledate("");
      setArea("");
      setSubblk("");
      setSupblk("");
      setButton("Submit");
      setPropModeDispo("");
      setPropPBType("");
      setPropOtherModeDipo("");
      setStallNo("");
      setAppMarketval("");
      setAppRentval("");
      setAppValdate("");
      setDistrictValue("");
      setRegionValue("");
      setAwardStatus("Unsold/Undisposed");
      setImageDataFlood(null);
      setImageDataSeis(null);
      setFileNameFlood("");
      setFileNameSeis("");
      setFileNameSiteMap("");

      setDistrictValueInv("");

      setRegionValueInv("");
      setWithAnoData("");
    };

    let res = UpdateInventory(
      propertyid.label,
      zoneordinace,
      catvalue,
      classvalue,
      lot,
      blk,
      phase,
      pack,
      lotarea,
      flrarea,
      subplan,
      subplanno,
      tctno,
      titlestat,
      statowner,
      otherowner,
      awardstat,
      physicalstat,
      physicalstatothers,
      structuredesc,
      frontage,
      shape,
      topography,
      foodassessment,
      sysmicanalysis,
      road,
      electricprov,
      waterprov,
      sesu,
      legalstat,
      actiontaken,
      executiveissuance,
      appstat,
      appvalid,
      pricingstat,
      pricevalid,
      modedispo,
      pbtype,
      othersmodedipo,
      propertyid.value,
      occupiedstat,
      inventoryid,
      useuuid,
      imagedata,
      acctstatus,
      awardstatusagreement.value,
      subplanfiledate,
      awardstatusagreementwith,
      area,
      subblk,
      supblk,
      pbawardee,
      pbdateaward,
      pbsellprice,
      pbcontype,
      pblessee,
      pbactoccu,
      pbleaserate,
      pbleaseterm,
      propmodedispo,
      proppbtype,
      propothersmodedipo,
      pbdateawardlease,
      usufructentity,
      agreementterm,
      stallno,
      appmarketval,
      apprentval,
      appvaldate,
      imagedataflood,
      imagedataseis,
      firstbiddate,
      secbiddate,
      foreclosedprop,
      pbsaleawardee,
      pbsaledateaward,
      pbsaleselprice,
      pbsalecontype,
      pbleaselessee,
      pbleasedateaward,
      pbleaseactoccu,
      pbleaseratepermon,
      pbleaseleaseterm,
      withanodata
    );
    res
      .then((value) => {
        if (value === "0000") {
          handleSuccess();
        } else {
          notifyWarning("Update Failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /////////////START OF SEARCH INPUT///////////

  const [isOpenpagetag, setIsOpenpagetag] = useState(true);
  const [query, setQuery] = useState("");

  const handlesetQuery = (event) => {
    if (event.target.value === "") {
      console.log(isOpenpagetag);
      setCurrentPage(1);
      setInvPerPage(30);
      setIsOpenpagetag(true);
    } else {
      setCurrentPage(1);
      setInvPerPage(allinventoryprojects.length);
      setIsOpenpagetag(false);
    }

    setQuery(event.target.value);
  };

  //////////////END OF SEARCH INPUT///////////////

  //////////////PAGINATION///////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [invPerPage, setInvPerPage] = useState(30);

  const indexOfLastInv = currentPage * invPerPage;
  const indexOfFirstInv = indexOfLastInv - invPerPage;
  const currentInv = allinventoryprojects.slice(
    indexOfFirstInv,
    indexOfLastInv
  );
  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);

  //////////////END OF PAGINATION///////////////

  const [openModalRemark, SetOpenModalRemark] = useState("");
  const handlePassDataModalValue = (data) => {
    SetOpenModalRemark(data.checkremark);
  };

  const handlePassDataValue = async (data) => {
    console.log(data.geomid);
    console.log(data.tbl_uuid);
    console.log(data.catvalue);
    getImagePass(data.tbl_uuid);

    if (data.catvalue === "Estate") {
      try {
        const value = await GetGeomEstate(data.geomid, data.tbl_uuid);
        let obj = JSON.parse(value.data[0].geojson);
        var coordinatesestate = obj.coordinates;
        let sumLat = 0;
        let sumLng = 0;
        let numPoints = 0;

        obj.coordinates.forEach((polygon) => {
          polygon.forEach((ring) => {
            ring.forEach((point) => {
              sumLat += point[1];
              sumLng += point[0];
              numPoints++;
            });
          });
        });

        const centerLat = sumLat / numPoints;
        const centerLng = sumLng / numPoints;
        var centeroid = [centerLng, centerLat];

        setPassGeom(coordinatesestate);
        setPassCenteroid(centeroid);
        setInventoryProfile(data);
        setopenInventoryModal(true);
      } catch (error) {
        console.log(error);
      }
    } else if (data.catvalue === "Non-estate") {
      try {
        const value = await GetGeomNonEstate(data.geomid, data.tbl_uuid);
        let obj = JSON.parse(value.data[0].geojson);
        var coordinatesestate = obj.coordinates;
        let sumLat = 0;
        let sumLng = 0;
        let numPoints = 0;

        obj.coordinates.forEach((polygon) => {
          polygon.forEach((ring) => {
            ring.forEach((point) => {
              sumLat += point[1];
              sumLng += point[0];
              numPoints++;
            });
          });
        });

        const centerLat = sumLat / numPoints;
        const centerLng = sumLng / numPoints;
        var centeroid = [centerLng, centerLat];

        setPassGeom(coordinatesestate);
        setPassCenteroid(centeroid);
        setInventoryProfile(data);
        setopenInventoryModal(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditInventory = (data) => {
    const propertyCode = data?.propertycode; // No default value
    if (propertyCode) {
      getProjectinfobyId(propertyCode);
    } else {
      console.error("Property code is null or undefined.");
      // Handle the case where propertyCode is null or undefined
    }
    setReadOnly(true);
    handleGetdistrictEdit(data);
    handleSelectAddressEdit(data);
    setCategory(data.catvalue);
    setClass(data.classvalue);
    setPropertyID({ value: data.propertycode, label: data.propertyid });
    setZoneOrdinance(data.zoneordinace);
    setBlk(data.blk);
    setLot(data.lot);
    setPhase(data.phase);
    setPackage(data.pack);
    setLotArea(data.lotarea);
    setStallNo(data.stallno);

    setFlrArea(data.flrarea);
    setSubPlan(data.subplan);
    setSubPlanNo(data.subplanno);
    setTCTNo(data.tctno);
    setTitleStat(data.titlestat);
    setStatOwner(data.statowner);
    setOtherOwner(data.otherowner);
    setAwardStatus(data.awardstat);
    setPhysicalStatus(data.physicalstat);
    setPhysicalStatOthers(data.physicalstatothers);
    setFrontage(data.frontage);
    setShape(data.shape);
    setTopography(data.topography);
    setFoodAssessment(data.foodassessment);
    setSysmicAnalysis(data.sysmicanalysis);
    setRoadName(data.road);
    setElectricProvider(data.electricprov);
    setWaterProvider(data.waterprov);
    setWaterSesu(data.sesu);
    setLegalStat(data.legalstat);
    setActionTaken(data.actiontaken);
    setExecutiveIssuances(data.executiveissuance);
    setAppStat(data.appstat);
    setAppValid(data.appvalid);
    setPricingStat(data.pricingstat);
    setPriceValid(data.pricevalid);
    setModeDispo(data.modedispo);
    setPBType(data.pbtype);
    setOtherModeDipo(data.othersmodedipo);
    setStructureDesc(data.structuredesc);
    setOccupiedStat(data.occupiedstat);
    setButton("Update");
    setInventoryId(data.tbl_id);
    // console.log(data)
    getImage(data.tbl_uuid);
    getImageFloodAssess(data.tbl_uuid);
    getImageSeismic(data.tbl_uuid);
    setUUID(data.tbl_uuid);
    setAccctstatus(data.acctstatus);
    setAwardStatusagreement({
      value: data.awardstatagreement,
      label: data.awardstatagreement,
    });
    setSubplanfiledate(data.subplanfiledate);
    setAwardStatusagreementwith(data.awardstatagreementwith);
    setArea(data.area);
    setSubblk(data.sub_blk);
    setSupblk(data.sup_blk);

    setPropModeDispo(data.propmodedispo);
    setPropPBType(data.proppbtype);
    setPropOtherModeDipo(data.propothersmodedipo);
    setAppMarketval(data.appmarketval);
    setAppRentval(data.apprentval);
    setAppValdate(data.appvaldate);
    setRegionValue(data.BP_ID);
    setDistrictValue(data.BP_DO_ID);
    setForeClosedProp(data.foreclosed_prop);
    setWithAnoData(data.wit_ano);
  };

  const getImage = (uuid) => {
    // setSetloading(true);
    GetImage(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchedImage(value.concatFile);
        } else {
          //ADD PLACEHOLDER
          setFetchedImage(myImage);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getImageFloodAssess = (uuid) => {
    // setSetloading(true);
    GetImageFlood(uuid)
      .then((value) => {
        if (value.concatFile) {
          FloodImagePrev(value.concatFile);
        } else {
          FloodImagePrev(myImage);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getImageSeismic = (uuid) => {
    // setSetloading(true);
    GetImageSeis(uuid)
      .then((value) => {
        if (value.concatFile) {
          SeisImagePrev(value.concatFile);
        } else {
          SeisImagePrev(myImage);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getImagePass = (uuid) => {
    // setSetloading(true);
    GetImage(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchedImagePass(value.concatFile);
        } else {
          //ADD PLACEHOLDER

          setFetchedImagePass(myImage);
        }
      })
      .catch((error) => {
        alert(error);
      });

    GetImageFlood(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchImageFlood(value.concatFile);
          console.log(value.concatFile);
        } else {
          //ADD PLACEHOLDER
          console.log("Seis Flood image not get");
          setFetchImageFlood(myImage);
        }
      })
      .catch((error) => {
        alert(error);
      });

    GetImageSeis(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchImageSeis(value.concatFile);
          console.log(value.concatFile);
        } else {
          //ADD PLACEHOLDER
          console.log("Seis Flood image not get");
          setFetchImageSeis(myImage);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handlePreviewImage = (imageprev) => {
    setFetchedImage(imageprev);
  };

  const handlePreviewImageFlood = (imageprev) => {
    FloodImagePrev(imageprev);
  };

  const handlePreviewImageSeis = (imageprev) => {
    SeisImagePrev(imageprev);
  };
  const getImageData = (file) => {
    setImageDta(file);
  };

  /////////////SORTING DATA/////////////////////
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState(null);

  const sortData = (column) => {
    setSortOrder("asc");
    const sortedData = allinventoryprojects.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueB === null) {
        return valueA === null ? 1 : -1;
      }

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
    setAllInventoryProject(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  // const sortData = (column) => {
  //   setSortOrder("asc");
  //   const sortedData = allinventoryprojects.sort((a, b) => {
  //     if (sortOrder === "asc") {
  //       return a[column].localeCompare(b[column]);
  //     } else {
  //       return b[column].localeCompare(a[column]);
  //     }
  //   });
  //   setAllInventoryProject(sortedData);
  //   setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  // };
  /////////////END OF SORTING DATA/////////////////////

  const renderSortingArrow = (column) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return null;
  };

  const uploadButtonRef = useRef(null);
  const handleUploadbuttonClick = () => {
    uploadButtonRef.current.click();
  };

  const getRegionalOffice = () => {
    // setSetloading(true);
    GetRegionalOffice()
      .then((value) => {
        setRegion(value.data);
        setRegionInv(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeRegion = (e) => {
    setRegionValue(e.target.value);
    // setSetloading(true);
    GetDistIndi(e.target.value)
      .then((value) => {
        setDistrict(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetdistrictEdit = (data) => {
    // setSetloading(true);
    GetDistIndi(data.BP_ID)
      .then((value) => {
        setDistrict(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectAddressEdit = (data) => {
    GetProjectInfobyCode(data.propertycode)
      .then((value) => {
        // Assuming setAddress is an array of objects
        const transformedData = value.data.map((item, index) => ({
          value: item.address,
          label: item.address + " / " + item.com_code,
          id: index,
        }));
        setAddressValue(transformedData);
        console.log(value.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleChangeRegionInv = (e) => {
    setRegionValueInv(e.target.value);

    const targetvalue = e.target.value;

    if (targetvalue) {
      GetInventoryUnsoldbyRegion(e.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
        })
        .catch((error) => {
          alert(error);
        });
      // setSetloading(true);

      GetDistIndi(e.target.value)
        .then((value) => {
          setDistrictInv(value.data);
          // setSetloading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getAllInventoryProjects();
      setDistrictValueInv("");
      setDistrictInv([]);
    }
  };

  const handleChangeDistInv = (e) => {
    setDistrictValueInv(e.target.value);
    const targetvalue = e.target.value;

    if (targetvalue) {
      GetUnsoldNonestateProject(e.target.value, regionValueInv)
        .then((value) => {
          setAllInventoryProject(value.data);
        })
        .catch((error) => {
          alert(error);
        });
      // setSetloading(true);
    } else {
      getAllInventoryProjects();
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];

        resolve(base64Data);
      };

      reader.onerror = () => {
        reject(new Error("Failed to convert image to Base64"));
      };
    });
  };

  const getImageDataFlood = (file) => {
    setImageDataFlood(file);
    console.log(file);
  };

  const getImageDataSeis = (file) => {
    setImageDataSeis(file);
    console.log(file);
  };

  const handleFileSelectFlood = (event) => {
    const selectedFiles = event.target.files;
    const fileflood = event.target.files[0];
    if (fileflood) {
      setFileNameFlood(event.target.files[0].name);
    } else {
      setFileNameFlood("");
    }

    if (selectedFiles && selectedFiles.length > 0) {
      handlePreviewImageFlood(URL.createObjectURL(selectedFiles[0]));
    }

    if (fileflood) {
      convertToBase64(fileflood)
        .then((base64Data) => {
          getImageDataFlood(base64Data);
        })
        .catch((error) => {
          console.error("Failed to convert image to Base64:", error);
        });
    }
  };

  const handleFileSelectSiteMap = (event) => {
    const selectedFiles = event.target.files;
    const filesitemap = event.target.files[0];

    if (filesitemap) {
      setFileNameSiteMap(event.target.files[0].name);
    } else {
      setFileNameSiteMap("");
    }

    if (selectedFiles && selectedFiles.length > 0) {
      handlePreviewImage(URL.createObjectURL(selectedFiles[0]));
    }

    if (filesitemap) {
      convertToBase64(filesitemap)
        .then((base64Data) => {
          getImageData(base64Data);
        })
        .catch((error) => {
          console.error("Failed to convert image to Base64:", error);
        });
    }
  };

  const handleDragOvermap = (event) => {
    event.preventDefault();
  };

  const handleDropmap = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];

    if (selectedFile) {
      setFileNameSiteMap(event.dataTransfer.files[0].name);
    } else {
      setFileNameSiteMap("");
    }

    if (selectedFile) {
      handlePreviewImage(URL.createObjectURL(selectedFile));
    }

    if (selectedFile) {
      convertToBase64(selectedFile)
        .then((base64Data) => {
          getImageData(base64Data);
        })
        .catch((error) => {
          console.error("Failed to convert image to Base64:", error);
        });
    }
  };

  const handleFileSelectSeis = (event) => {
    const selectedFiles = event.target.files;
    const fileseis = event.target.files[0];

    if (fileseis) {
      setFileNameSeis(event.target.files[0].name);
    } else {
      setFileNameSeis("");
    }

    if (selectedFiles && selectedFiles.length > 0) {
      handlePreviewImageSeis(URL.createObjectURL(selectedFiles[0]));
    }

    if (fileseis) {
      convertToBase64(fileseis)
        .then((base64Data) => {
          getImageDataSeis(base64Data);
        })
        .catch((error) => {
          console.error("Failed to convert image to Base64:", error);
        });
    }
  };

  const handleChangeForelosedProp = (event) => {
    if (event.target.checked) {
      setForeClosedProp(event.target.value);
    } else {
      setForeClosedProp("");
    }
  };

  useEffect(() => {
    fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setAccessLevel(responseJson.response.useraccess);
      })
      .catch((error) => {
        console.log(error);
      });
    if (!selectedData) {
      getRegionalOffice();
    }

    getAllInventoryProjects();
    getAllProjectsEstate();
    getAllProjectsNonEstate();
  }, []);

  useEffect(() => {
    if (selectedData) {
      handleEditInventory(selectedData);
      // Resetting state after action
      navigate(location.pathname, { state: {} });
    }
  }, [selectedData, navigate, location.pathname]);
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sidebar />
      <div>
        <div className="content-wrapper" style={{ minHeight: 743 }}>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Form Unsold/Undisposed</h1>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                {/* left */}
                <div className="col-lg-6">
                  <div
                    className="overflow-auto"
                    // style={{ height: "1500px", overflow: "auto" }}
                    style={{ maxHeight: "calc(100vh - 100px)" }}
                  >
                    <div className="mb-3">
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            1. Lot Classification
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {accesslevel === "1" ? (
                              <div className="col-lg-6">
                                <div className="form-group mt-2">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classification"
                                      // onClick={()=>setCategory("1")}
                                      value="Non-estate"
                                      checked={catvalue === "Non-estate"}
                                      onChange={handleChangeCat}
                                      id="classnonestate"
                                    />
                                    <label
                                      className="form-check-label "
                                      htmlFor="classnonestate"
                                    >
                                      Non-estate
                                    </label>
                                  </div>
                                </div>
                                <div className=" ml-2">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classNon"
                                      value="1"
                                      checked={classvalue === "1"}
                                      onChange={handleChangeClass}
                                      id="noncommercial"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="noncommercial"
                                    >
                                      Commercial
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classNon"
                                      value="2"
                                      checked={classvalue === "2"}
                                      onChange={handleChangeClass}
                                      id="nonindustrial"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="nonindustrial"
                                    >
                                      Industrial
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classNon"
                                      value="3"
                                      checked={classvalue === "3"}
                                      onChange={handleChangeClass}
                                      id="nonmix"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="nonmix"
                                    >
                                      Institutional
                                    </label>
                                  </div>

                                  <div className="form-check ml-4 ">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={"foreclosed"}
                                      checked={foreclosedprop === "foreclosed"}
                                      onChange={handleChangeForelosedProp}
                                      id="foreclosed"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="foreclosed"
                                    >
                                      Foreclosed/Acquired Property
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {accesslevel === "3" ? (
                              <div className="col-lg-6">
                                <span className="">As Per LIS</span>
                                <div className="form-group mt-2">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classification"
                                      //    onClick={()=>setCategory("2")}
                                      value="Estate"
                                      checked={catvalue === "Estate"}
                                      onChange={handleChangeCat}
                                      id="classestate"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="classestate"
                                    >
                                      Estate
                                    </label>
                                  </div>
                                </div>
                                <div className=" ml-2">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classCII"
                                      value="5"
                                      checked={classvalue === "5"}
                                      onChange={handleChangeClass}
                                      id="estatecommercial"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="estatecommercial"
                                    >
                                      Commercial
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classCII"
                                      value="6"
                                      checked={classvalue === "6"}
                                      onChange={handleChangeClass}
                                      id="estateindustrial"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="estateindustrial"
                                    >
                                      Industrial
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="classCII"
                                      value="8"
                                      checked={classvalue === "8"}
                                      onChange={handleChangeClass}
                                      id="estateinstitutional"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="estateinstitutional"
                                    >
                                      Institutional
                                    </label>
                                  </div>

                                  <div className="form-check ml-4 ">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={"foreclosed"}
                                      checked={foreclosedprop === "foreclosed"}
                                      onChange={handleChangeForelosedProp}
                                      id="foreclosed"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="foreclosed"
                                    >
                                      Foreclosed/Acquired Property
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {accesslevel === "1" && !readonly ? (
                            <div>
                              <hr></hr>
                              <div
                                className="d-flex  flex-column"
                                style={{ gap: "5px" }}
                              >
                                <div
                                  className="text-danger small mb-2"
                                  role="alert"
                                >
                                  Please ensure that your region and district
                                  are correct.
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="w-50 form-check-label">
                                    Choose Region:
                                  </label>

                                  <select
                                    className="form-control w-50"
                                    value={regionValue}
                                    onChange={handleChangeRegion}
                                    // disabled={readonly ? true : false}
                                  >
                                    <option disabled defaultValue value="">
                                      Select Options
                                    </option>
                                    {region.map((data) => (
                                      <option
                                        key={data.BP_ID}
                                        value={data.BP_ID}
                                      >
                                        {data.BP_REGION}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="d-flex align-items-center">
                                  <label className="w-50 form-check-label">
                                    Choose District:
                                  </label>

                                  <select
                                    className="form-control w-50"
                                    onChange={(e) =>
                                      setDistrictValue(e.target.value)
                                    }
                                    value={districtValue}
                                    // disabled={readonly ? true : false}
                                  >
                                    <option disabled defaultValue value="">
                                      Select Options
                                    </option>
                                    {district.map((data) => (
                                      <option
                                        key={data.BP_DO_ID}
                                        value={data.BP_DO_ID}
                                      >
                                        {data.BP_DO_OFFICE}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : accesslevel === "1" && readonly ? (
                            ""
                          ) : null}
                          <hr></hr>
                          <div
                            className="d-flex flex-column"
                            style={{ gap: "5px" }}
                          >
                            <div className="d-flex   align-items-center ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Property Identification:
                              </label>

                              {catvalue === "Non-estate" ? (
                                <CreatableSelect
                                  className="w-50"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                    }),
                                  }}
                                  options={nonestateprojects?.map(
                                    (data, index) => ({
                                      value: data.id,
                                      label:
                                        data.location + "/" + data.propname,
                                    })
                                  )}
                                  value={propertyid}
                                  placeholder="Select Project"
                                  onChange={handleSetPropertyIDNonEstate}
                                  isClearable={true}
                                  isSearchable={true}
                                />
                              ) : (
                                <Select
                                  className="w-50"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                    }),
                                  }}
                                  options={estateprojects?.map(
                                    (data, index) => ({
                                      value: data.BP_PR_CODE,
                                      label: data.BP_PR_NAME,
                                      id: index,
                                    })
                                  )}
                                  value={propertyid}
                                  class="form-control"
                                  placeholder="Select Project"
                                  onChange={handleSetPropertyIDEstate}
                                  isClearable={true}
                                  isSearchable={true}
                                />
                              )}
                            </div>

                            {catvalue === "Estate" ? (
                              <div className="d-flex align-items-center ">
                                <label className="form-check-label w-50">
                                  Select Address:
                                </label>
                                <CreatableSelect
                                  className="w-50"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                    }),
                                  }}
                                  options={setaddress?.map((data, index) => ({
                                    value: data.com_code,
                                    label: data.address + " / " + data.com_code,
                                    id: index,
                                  }))}
                                  value={addressvalue}
                                  placeholder="Select Address"
                                  onChange={handleSetPropertyBlock}
                                  isSearchable={true}
                                  isClearable={true}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Land Use Classification, as per Zoning
                                Ordinance:
                              </label>
                              <input
                                className="form-control  w-50"
                                value={zoneordinace}
                                onChange={(e) =>
                                  setZoneOrdinance(e.target.value)
                                }
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            2. Lot Information
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className=" ">
                            <div className="ml-2  ">
                              <div className="">
                                <p className="">2.1 Lot Identification</p>
                                <div
                                  className=" d-flex flex-column"
                                  style={{ gap: "5px" }}
                                >
                                  <div className=" d-flex align-items-center ">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Lot:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={lot}
                                      onChange={(e) => setLot(e.target.value)}
                                    />
                                  </div>
                                  <div className=" d-flex align-items-center ">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Block:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={blk}
                                      onChange={(e) => setBlk(e.target.value)}
                                    />
                                  </div>
                                  <div className=" d-flex align-items-center">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Sub-block:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={subblk}
                                      onChange={(e) =>
                                        setSubblk(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className=" d-flex align-items-center">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Superblock:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={supblk}
                                      onChange={(e) =>
                                        setSupblk(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className=" d-flex align-items-center">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Phase:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={phase}
                                      onChange={(e) => setPhase(e.target.value)}
                                    />
                                  </div>
                                  <div className=" d-flex align-items-center">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Package:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={pack}
                                      onChange={(e) =>
                                        setPackage(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className=" d-flex align-items-center">
                                    <label
                                      className="form-check-label w-50"
                                      htmlFor=""
                                    >
                                      Area:
                                    </label>
                                    <input
                                      className="form-control  w-50"
                                      value={area}
                                      onChange={(e) => setArea(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="">
                                <p className="">2.2 Lot Information</p>
                                <div className=" d-flex">
                                  <div
                                    className="w-50 d-flex flex-column"
                                    style={{ gap: "10px" }}
                                  >
                                    <label className="form-check-label ">
                                      Lot
                                    </label>
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ gap: "5px" }}
                                    >
                                      <label
                                        className="form-check-label "
                                        htmlFor=""
                                      >
                                        Area:
                                      </label>
                                      <input
                                        className="form-control  w-50"
                                        value={lotarea}
                                        onChange={(e) =>
                                          setLotArea(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label "
                                        htmlFor=""
                                      >
                                        sq.m.
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className="w-50 d-flex flex-column  "
                                    style={{ gap: "5px" }}
                                  >
                                    <div className="d-flex align-items-center ">
                                      <label className="form-check-label ">
                                        Unit/Stall No.:
                                      </label>
                                      <input
                                        className="form-control  w-50 ml-1"
                                        value={stallno}
                                        onChange={(e) =>
                                          setStallNo(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div
                                      className="d-flex align-items-center"
                                      // style={{ gap: "5px" }}
                                    >
                                      <label
                                        className="form-check-label "
                                        htmlFor=""
                                      >
                                        Floor Area:
                                      </label>
                                      <input
                                        className="form-control  w-50 ml-4"
                                        value={flrarea}
                                        onChange={(e) =>
                                          setFlrArea(e.target.value)
                                        }
                                      />
                                      <label
                                        className="form-check-label ml-1 "
                                        htmlFor=""
                                      >
                                        sq.m.
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="">
                                <p className="">2.3 Subdivision Plan</p>
                                <div className="form-group ">
                                  <div className="d-flex align-items-center">
                                    <div className="w-50">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="sample"
                                          value="Subdivision Plan No."
                                          checked={
                                            // (subplanno.length > 1 && subplanno.length != 0) ||
                                            subplan === "Subdivision Plan No."
                                          }
                                          onChange={handleChangeSubPlan}
                                          id="subplanno"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="subplanno"
                                        >
                                          Subdivision Plan No.
                                        </label>
                                      </div>
                                    </div>

                                    <input
                                      className="form-control  w-50"
                                      value={subplanno}
                                      onChange={handleSubdivisionPlan}
                                    />
                                  </div>

                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sample"
                                      value="Not yet Subdivided"
                                      checked={subplan === "Not yet Subdivided"}
                                      onChange={handleChangeSubPlan}
                                      id="notyetsubd"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="notyetsubd"
                                    >
                                      Not yet Subdivided
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sample"
                                      value="For Subdivision"
                                      onChange={handleChangeSubPlan}
                                      checked={subplan === "For Subdivision"}
                                      id="forsubdivision"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="forsubdivision"
                                    >
                                      For Subdivision
                                    </label>
                                  </div>

                                  <div className="d-flex align-items-center mt-2">
                                    <label className="w-50 form-check-label">
                                      Filed on:
                                    </label>

                                    <input
                                      type="date"
                                      className="input w-50 form-control"
                                      onChange={handleSubdivisionPlanfiledate}
                                      value={subplanfiledate}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="">
                                <p className="">2.4 Status of Title</p>
                                <div className="form-group ">
                                  <div
                                    className=" d-flex flex-column"
                                    style={{ gap: "5px" }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <label
                                        className="form-check-label w-50"
                                        htmlFor="subplanno"
                                      >
                                        Registered Owner:
                                      </label>

                                      <input
                                        className="form-control  w-50"
                                        value={otherowner}
                                        onChange={handleOccupiedOthers}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label
                                        className="form-check-label w-50"
                                        htmlFor="subplanno"
                                      >
                                        TCT/OCT No.
                                      </label>

                                      <input
                                        className="form-control  w-50"
                                        value={tctno}
                                        onChange={(e) =>
                                          setTCTNo(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="titleTstat"
                                      id="mothertitle"
                                      checked={titlestat === "Mother Title"}
                                      value="Mother Title"
                                      onChange={handleChangeTitleStat}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="mothertitle"
                                      value="Mother Title"
                                    >
                                      Mother Title
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="titleTstat"
                                      value="Individual Title"
                                      checked={titlestat === "Individual Title"}
                                      onChange={handleChangeTitleStat}
                                      id="individualtitle"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="individualtitle"
                                    >
                                      Individual Title
                                    </label>
                                  </div>

                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="titleTstat"
                                      value="Pending release of Individual TCTs"
                                      checked={
                                        titlestat ===
                                        "Pending release of Individual TCTs"
                                      }
                                      onChange={handleChangeTitleStat}
                                      id="forpendingreleasetct"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="forpendingreleasetct"
                                    >
                                      Pending release of Individual Title
                                    </label>
                                  </div>

                                  <label className="form-check-label mt-2">
                                    Encumbrances:
                                  </label>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="titleTstat"
                                      value="Clear"
                                      checked={titlestat === "Clear"}
                                      onChange={handleChangeTitleStat}
                                      id="clear"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="clear"
                                    >
                                      Clear
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="w-50">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="titleTstat"
                                          value="With Annotation"
                                          checked={
                                            titlestat === "With Annotation"
                                          }
                                          onChange={handleChangeTitleStat}
                                          id="withannotation"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="withannotation"
                                        >
                                          With annotation/s
                                        </label>
                                      </div>
                                    </div>
                                    <input
                                      className="form-control  w-50"
                                      value={withanodata}
                                      onChange={handleWithAnoData}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            3. Occupancy Status
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="physicalstat"
                                value="Occupied"
                                checked={physicalstat === "Occupied"}
                                onChange={handleChangePhysicalStatus}
                                id="phystatusoccupied"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="phystatusoccupied"
                              >
                                Occupied
                              </label>
                            </div>
                            <div className=" ml-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="physicalstat"
                                  value="Informal Settler/s"
                                  checked={occupiedstat.includes(
                                    "Informal Settler/s"
                                  )}
                                  onChange={handleChangeOccupied}
                                  id="phystatisf"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="phystatisf"
                                >
                                  Informal Settler/s
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="phystatgoventity"
                                  value="Government Entity"
                                  checked={occupiedstat.includes(
                                    "Government Entity"
                                  )}
                                  onChange={handleChangeOccupied}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="phystatgoventity"
                                >
                                  Government Entity
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="Church or other Institution"
                                  checked={occupiedstat.includes(
                                    "Church or other Institution"
                                  )}
                                  onChange={handleChangeOccupied}
                                  id="phystatchurcorotherinsti"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="phystatchurcorotherinsti"
                                >
                                  Church or other Institutions
                                </label>
                              </div>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="physicalstat"
                                value="Vacant"
                                checked={physicalstat === "Vacant"}
                                onChange={handleChangePhysicalStatus}
                                id="phystatvacant"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="phystatvacant"
                              >
                                Vacant
                              </label>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="form-check w-50">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="physicalstat"
                                  value="Others"
                                  checked={physicalstat === "Others"}
                                  onChange={handleChangePhysicalStatus}
                                  id="phystatothers"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="phystatothers"
                                >
                                  Others:
                                </label>
                              </div>
                              <input
                                className="input w-50 form-control"
                                value={physicalstatothers}
                                onChange={handlePhysicalOthers}
                              />
                            </div>
                          </div>
                          <div className="mt-3">
                            <label className="form-check-label">
                              {" "}
                              Short Description of existing structure/s or
                              utilization:
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              style={{
                                resize: "none",
                              }}
                              value={structuredesc}
                              onChange={(e) => setStructureDesc(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            4. Physical Profile and Utilities
                          </h5>
                        </div>
                        <div className="card-body">
                          <div
                            className=" d-flex flex-column "
                            style={{ gap: "5px" }}
                          >
                            <div className=" d-flex  align-items-center ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Frontage Width:
                              </label>
                              <input
                                className="form-control  w-50"
                                value={frontage}
                                onChange={(e) => setFrontage(e.target.value)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="refer to side along main road"
                              />
                            </div>
                            <div className=" d-flex  align-items-center ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Shape:
                              </label>
                              <input
                                className="form-control  w-50"
                                value={shape}
                                onChange={(e) => setShape(e.target.value)}
                              />
                            </div>
                            <div className=" d-flex  align-items-center">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Topography:
                              </label>
                              <input
                                className="form-control  w-50"
                                value={topography}
                                onChange={(e) => setTopography(e.target.value)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="refer to latest CLUP Maps"
                              />
                            </div>
                            <div className=" d-flex  align-items-center ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                <a
                                  href="https://noah.up.edu.ph"
                                  target="_blank"
                                >
                                  Flood Assessment:
                                </a>
                              </label>

                              <div className="input-group w-50">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleInputFile"
                                    onChange={handleFileSelectFlood}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {fileNameFlood
                                      ? fileNameFlood
                                      : "Choose file"}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              ></label>

                              <input
                                className="form-control w-50"
                                value={foodassessment}
                                onChange={(e) =>
                                  setFoodAssessment(e.target.value)
                                }
                              />
                            </div>

                            <div className=" d-flex  align-items-center ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                <a
                                  href="https://hazardhunter.georisk.gov.ph"
                                  target="_blank"
                                >
                                  {" "}
                                  Seismic Analysis:
                                </a>
                              </label>

                              <div className="input-group w-50">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleInputFile"
                                    onChange={handleFileSelectSeis}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {fileNameSeis
                                      ? fileNameSeis
                                      : "Choose file"}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              ></label>

                              <input
                                className="form-control  w-50"
                                value={sysmicanalysis}
                                onChange={(e) =>
                                  setSysmicAnalysis(e.target.value)
                                }
                                data-toggle="tooltip"
                                data-placement="top"
                                title="refer to Hazard Hunter PH website: https://hazardhunter.georisk.gov.ph/"
                              />
                            </div>

                            <div className=" d-flex   ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Easements/Right-of-way:
                                <br />
                                (name of road)
                              </label>
                              <div className="w-50">
                                {road.map((input, index) => (
                                  <div className="input-group mb-1" key={index}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={input}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      placeholder={`Road ${index + 1}`}
                                    />
                                    <div className="input-group-append">
                                      {road.length > 1 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            removeInputField(index)
                                          }
                                        >
                                          <i className="fas fa-minus"></i>
                                        </button>
                                      )}
                                      {index === road.length - 1 && (
                                        <button
                                          type="button"
                                          className="btn btn-success ml-2"
                                          onClick={addInputField}
                                        >
                                          <i className="fas fa-plus "></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className=" d-flex  align-items-center">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Electricity Provider:
                              </label>
                              <input
                                className="form-control  w-50"
                                value={electricprov}
                                onChange={(e) =>
                                  setElectricProvider(e.target.value)
                                }
                              />
                            </div>
                            <div className=" d-flex ">
                              <label
                                className="form-check-label w-50"
                                htmlFor=""
                              >
                                Water Provider:
                              </label>
                              <input
                                className="form-control  w-50"
                                value={waterprov}
                                onChange={(e) =>
                                  setWaterProvider(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            5. Socio-Economic Class of the Place or its
                            Occupants
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sesu"
                                value="High Income Class"
                                checked={sesu === "High Income Class"}
                                onChange={handleChangeSesu}
                                id="socioecohighclass"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="socioecohighclass"
                              >
                                High Income Class
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sesu"
                                value="Middle Income Class"
                                checked={sesu === "Middle Income Class"}
                                onChange={handleChangeSesu}
                                id="socioecomidclass"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="socioecomidclass"
                              >
                                Middle Income Class
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sesu"
                                value="Low Income Class"
                                checked={sesu === "Low Income Class"}
                                onChange={handleChangeSesu}
                                id="socioecolowclass"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="socioecolowclass"
                              >
                                Low Income Class
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sesu"
                                value="Mixed-Income Class"
                                checked={sesu === "Mixed-Income Class"}
                                onChange={handleChangeSesu}
                                id="socioecomixlass"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="socioecomixlass"
                              >
                                Mixed-Income Class
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            6. Legal Status
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="legalstat"
                                value="1"
                                checked={legalstat === "1"}
                                onChange={handleChangeLegalStat}
                                id="legalstatwithcase"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="legalstatwithcase"
                              >
                                with pending case
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="legalstat"
                                value="2"
                                checked={legalstat === "2"}
                                onChange={handleChangeLegalStat}
                                id="legalstatwithoutcase"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="legalstatwithoutcase"
                              >
                                without pending case
                              </label>
                            </div>

                            <div className=" mt-2">
                              <label className="form-check-label">
                                {" "}
                                Action taken/to be taken, if any:
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                style={{
                                  resize: "none",
                                }}
                                value={actiontaken}
                                onChange={(e) => setActionTaken(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            7. Historical Background
                          </h5>
                        </div>
                        <div className="card-body">
                          <textarea
                            className="form-control"
                            rows={3}
                            style={{
                              resize: "none",
                            }}
                            value={executiveissuance}
                            onChange={(e) =>
                              setExecutiveIssuances(e.target.value)
                            }
                            placeholder="In a bullet-point summary, and please provide annexes (if any)"
                          />
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            8. Appraisal Status
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="form-check w-50">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="appstat"
                                value="1"
                                checked={appstat === "1"}
                                onChange={handleChangeAppStat}
                                id="appstatwithappraised"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="appstatwithappraised"
                              >
                                with appraised values:
                              </label>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-column"
                            style={{ gap: "5px" }}
                          >
                            {appstat === "1" ? (
                              <div className="d-flex align-items-center">
                                <div className="form-check w-50">
                                  <label className="form-check-label">
                                    Market value per sqm.
                                  </label>
                                </div>
                                <input
                                  className="input w-50 form-control"
                                  value={appmarketval}
                                  onChange={(e) => {
                                    setAppMarketval(e.target.value);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {appstat === "1" ? (
                              <div className="d-flex align-items-center">
                                <div className="form-check w-50">
                                  <label className="form-check-label">
                                    Market rent per sqm.
                                  </label>
                                </div>
                                <input
                                  className="input w-50 form-control"
                                  value={apprentval}
                                  onChange={(e) => {
                                    setAppRentval(e.target.value);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {appstat === "1" ? (
                              <div className="d-flex align-items-center">
                                <div className="form-check w-50">
                                  <label className="form-check-label">
                                    Valid until:
                                  </label>
                                </div>
                                <input
                                  type="date"
                                  className="input w-50 form-control"
                                  value={appvalid}
                                  onChange={(e) => {
                                    setAppValid(e.target.value);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="2"
                                checked={appstat === "2"}
                                onChange={handleChangeAppStat}
                                name="appstat"
                                id="appstatforappraisal"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="appstatforappraisal"
                              >
                                for appraisal
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header">
                          <h5 className="card-title font-weight-bold">
                            9. Pricing Status
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="pricingstat"
                              value="1"
                              checked={pricingstat === "1"}
                              onChange={handleChangePricingStat}
                              id="pricingstatwithvalidpricing"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pricingstatwithvalidpricing"
                            >
                              with Valid Pricing (Selling Price and/or Lease
                              Rate)
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="form-check w-50">
                              <label className="form-check-label ">
                                Validity until:
                              </label>
                            </div>
                            <input
                              type="date"
                              className="input w-50 form-control"
                              value={pricevalid}
                              onChange={handleChangePricingStatValid}
                            />
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="pricingstat"
                              value="2"
                              checked={pricingstat === "2"}
                              onChange={handleChangePricingStat}
                              id="pricingstatexpiredpricing"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pricingstatexpiredpricing"
                            >
                              Expired Pricing
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <h5 className="card-title font-weight-bold">
                            10. Proposed Mode of Disposition
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="form-check w-50">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propmodedis"
                              value="1"
                              checked={propmodedispo === "1"}
                              onChange={handleProposedModeDispo}
                              id="proppbbid"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="proppbbid"
                            >
                              For public Bidding
                            </label>
                          </div>

                          <div className="d-flex align-items-center">
                            <div className="form-check w-50">
                              <label className="form-check-label ">
                                Negotiated:
                              </label>
                            </div>
                            <div className="form-check w-50 p-0 ">
                              <div
                                className="d-flex ml-0"
                                style={{ gap: "25px" }}
                              >
                                <div className="d-flex" style={{ gap: "5px" }}>
                                  <input
                                    type="checkbox"
                                    name="proppbtype"
                                    value="Negotiated Sale"
                                    checked={proppbtype === "Negotiated Sale"}
                                    onChange={handleProposedPBType}
                                    id="propmodedissale"
                                  />{" "}
                                  <label
                                    className="form-check-label"
                                    htmlFor="propmodedissale"
                                  >
                                    Sale
                                  </label>
                                </div>
                                <div className="d-flex" style={{ gap: "5px" }}>
                                  <input
                                    type="checkbox"
                                    name="proppbtype"
                                    value="Negotiated Lease"
                                    checked={proppbtype === "Negotiated Lease"}
                                    onChange={handleProposedPBType}
                                    id="propmodedislease"
                                  />{" "}
                                  <label
                                    className="form-check-label"
                                    htmlFor="propmodedislease"
                                  >
                                    Lease
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="propmodedis"
                                value="2"
                                checked={propmodedispo === "2"}
                                onChange={handleProposedModeDispo}
                                id="modedisfinancelease"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="modedisfinancelease"
                              >
                                Direct Award
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="form-check w-50">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="propmodedis"
                                  value="3"
                                  checked={propmodedispo === "3"}
                                  onChange={handleProposedModeDispo}
                                  id="propmodedisothers"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="propmodedisothers"
                                >
                                  Others:
                                </label>
                              </div>
                              <input
                                className="input w-50 form-control"
                                value={propothersmodedipo}
                                onChange={handleProposedModeDispoOthers}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="e.g., for donation, for usufruct, etc."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {(accesslevel === "1" || accesslevel === "3") &&
                      readonly ? (
                        <div className="card card-info card-outline">
                          <div className="card-header ">
                            <h5 className="card-title font-weight-bold">
                              Mark as sold?
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="yesorno"
                                id="markyes"
                                value="Sold/Disposed"
                                onChange={handleChangeAwardStatus}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="markyes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="yesorno"
                                id="markno"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="markno"
                                value="Unsold/Undisposed"
                                onChange={handleChangeAwardStatus}
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {buttonstat === "Submit" ? (
                        <button
                          className="btn btn-primary pl-3 pr-4"
                          onClick={handleAddInventory}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary pl-3 pr-4"
                          onClick={handleUpdateInventory}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* left */}
                {/* right */}
                <div className="col-lg-6">
                  <div
                    className="overflow-auto"
                    style={{ maxHeight: "calc(100vh - 100px)" }}
                  >
                    <div
                      className="card card-info card-outline"
                      style={{ height: "500px" }}
                    >
                      <div className="card-header ">
                        <h5 className="card-title font-weight-bold">Map</h5>
                      </div>
                      <div className="card-body">
                        <MapContainer
                          style={{ height: "100%", minHeight: "100%" }}
                          center={[15.1762632566058, 120.666889136715]}
                          zoom={17}
                          scrollWheelZoom={false}
                          ref={mapRef}
                        >
                          <TileLayer
                            url="https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
                            zoom={13}
                            maxZoom={21}
                          />

                          {setcenter.length === 0 ? (
                            ""
                          ) : (
                            <Marker
                              position={setcenter}
                              icon={
                                new Icon({
                                  iconUrl: markerIconPng,
                                  iconSize: [25, 41],
                                  iconAnchor: [12, 41],
                                })
                              }
                            ></Marker>
                          )}
                          <Polygon
                            pathOptions={borderOptions}
                            positions={multiPolygon}
                          />
                          {setcenter.length === 0 ? "" : ""}
                          <LayersControl>
                            <LayersControl.Overlay checked name="CII">
                              <WMSTileLayer
                                url="https://maps.nha.gov.ph/geoserver/wms?"
                                opacity={1}
                                transparent
                                format="image/png"
                                layers="NHA_PH:trial1_acdue_cii"
                                minZoom={8}
                                maxZoom={21}
                              />
                            </LayersControl.Overlay>
                            <LayersControl.Overlay
                              checked
                              name="CII Non-estate"
                            >
                              <WMSTileLayer
                                url="https://maps.nha.gov.ph/geoserver/wms?"
                                opacity={1}
                                transparent
                                format="image/png"
                                layers="NHA_PH:nha_cii"
                                minZoom={8}
                                maxZoom={21}
                              />
                            </LayersControl.Overlay>
                            <LayersControl.Overlay checked name="CII Marker">
                              <WMSTileLayer
                                url="https://maps.nha.gov.ph/geoserver/wms?"
                                opacity={1}
                                transparent
                                format="image/png"
                                layers="NHA_PH:nha_cii_loc"
                                minZoom={8}
                                maxZoom={21}
                              />
                            </LayersControl.Overlay>
                          </LayersControl>
                        </MapContainer>
                      </div>
                    </div>
                    <div className="card card-info card-outline">
                      <div className="card-header ">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="card-title font-weight-bold">
                            Upload Site Map (with timestamp)
                          </h5>
                        </div>
                      </div>
                      <div
                        className="card-body overflow-auto "
                        style={{ cursor: "pointer" }}
                        onClick={handleUploadbuttonClick}
                        onDragOver={handleDragOvermap}
                        onDrop={handleDropmap}
                      >
                        <img
                          src={fetchimage}
                          style={{
                            objectFit: "fill",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </div>

                      <div className="card-footer bg-transparent clearfix  ">
                        <div className="text-muted">
                          Drag & drop image file here or click to select image
                          file
                        </div>
                        <div className="custom-file">
                          <input
                            ref={uploadButtonRef}
                            type="file"
                            className="custom-file-input"
                            id="exampleInputFile"
                            onChange={handleFileSelectSiteMap}
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {fileNameSiteMap ? fileNameSiteMap : "Choose file"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* preview flood  */}
                    {floodimageprev && (
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title font-weight-bold">
                              Flood Assessment (preview)
                            </h5>
                          </div>
                        </div>
                        <div className="card-body overflow-auto ">
                          <img
                            src={floodimageprev}
                            style={{
                              objectFit: "fill",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* seiss preview */}
                    {seisimageprev && (
                      <div className="card card-info card-outline">
                        <div className="card-header ">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title font-weight-bold">
                              Seismic Analysis (preview)
                            </h5>
                          </div>
                        </div>
                        <div className="card-body overflow-auto ">
                          <img
                            src={seisimageprev}
                            style={{
                              objectFit: "fill",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* right */}
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* INVENTORY MODAL  */}

      <InventoryProfile
        show={openInventoryModal}
        closeModal={setopenInventoryModal}
        inventoryprofile={inventoryprofile}
        passgeom={passgeom}
        passcenteroid={passcenteroid}
        fetchimagepass={fetchimagepass}
        getAllInventoryProjects={getAllInventoryProjects}
        fetchimageflood={fetchimageflood}
        fetchimageseis={fetchimageseis}
      />

      {/* END INVENTORY MODAL  */}

      {/* REMARK MODAL */}

      <div
        className="modal fade"
        id="modal-remark"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog  ">
          {" "}
          {/* Added modal-sm class */}
          <div className="modal-content">
            <div className="modal-header bg-info">
              <div className="p-0">
                <h4 className="modal-title">Remark</h4>
              </div>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body p-0 ">
              <div className="m-2">{openModalRemark}</div>
            </div>

            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* END REMARK MODAL */}

      <Footer />
    </div>
  );
};

export default Form2;
